<template>
  <section ref="MainContent" class="relative z-10 w-full pb-20">
    <div class="overflow-hidden main_container">
      <header class="relative w-full mb-14">
        <p data-section-sub-title class="mb-0 text-lg font-bold text-primary">
          NEWS LIST
        </p>
        <div class="relative mb-5">
          <h3
            data-section-title
            class="text-3xl font-black text-white sm:text-5xl"
          >
            最新消息
          </h3>
        </div>
      </header>

      <ol
        class="flex flex-wrap items-stretch -mx-5 overflow-hidden lg:flex-nowrap"
      >
        <li
          v-for="(item, item_index) in news_data"
          :key="`news_${item_index}`"
          class="flex-shrink-0 w-full px-5 mb-5 lg:w-1/3"
        >
          <div data-section-card class="flex flex-col w-full h-full">
            <router-link
              :to="`/news/${item.NewsID}`"
              class="block w-full mb-5 overflow-hidden aspect-video"
            >
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="item.Title"
                class="block object-cover w-full h-full transition-all duration-500 transform hover:scale-105"
              />
            </router-link>
            <div class="flex items-center mb-3">
              <p class="mr-4 text-sm work_sans md:text-base text-zinc-400">
                {{ item.created_at.slice(0, 10) }}
              </p>
              <p
                class="px-2 py-1 text-xs text-white border border-black bg-primary"
              >
                {{ item.CategoryTitle }}
              </p>
            </div>

            <router-link
              :to="`/news/${item.NewsID}`"
              class="mb-3 font-bold text-white transition-all duration-300 md:text-xl hover:text-transparent sm:text-2xl bg-gradient-to-r from-secondary to-primary bg-clip-text"
            >
              {{ item.Title }}
            </router-link>
            <div class="flex-1 mb-8 text-sm text-zinc-400">
              {{ GetContent(item.Content) }}
            </div>
          </div>
        </li>
      </ol>

      <div data-section-card class="flex justify-center">
        <a
          href="/news"
          class="inline-flex items-center py-2 pl-8 pr-6 text-sm font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-base bg-primary md:hover:bg-opacity-100"
          >查看所有文章
          <i class="block ml-3 transform -rotate-45 icon-arrow-right"></i>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { AboutSection } from "@/gsap/home/AboutSection";
export default {
  name: "NewsListSection",
  data() {
    return {
      section_gsap: null,
    };
  },
  computed: {
    news_data() {
      return this.$store.state.news_data.slice(0, 4);
    },
  },
  methods: {
    GetContent(val) {
      let msg = val.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ""); //去掉npsp
      msg = msg.replace(/&nbsp;/gi, ""); //&nbsp;

      return msg.length > 50 ? msg.slice(0, 50) + "..." : msg;
    },
    SetGsap() {
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new AboutSection(this.$refs.MainContent);
    this.section_gsap.reset();
    this.section_gsap.setup();
  },
};
</script>
