<template>
  <section class="relative z-10 w-full">
    <div ref="GlideSlider" class="glide">
      <div class="relative glide__track" data-glide-el="track">
        <ul class="relative z-0 glide__slides">
          <li
            class="relative w-full glide__slide"
            v-for="(item, item_index) in carousel_data"
            :key="`carousel_${item_index}`"
          >
            <div
              class="absolute z-20 flex items-start glide__slide_info md:left-5 md:bottom-10 left-3 bottom-5"
            >
              <div class="pt-2 mr-2">
                <span
                  class="block glide__slide_info_line w-6 md:w-10 h-[1px] bg-white"
                ></span>
              </div>
              <div>
                <p
                  class="text-xs font-bold glide__slide_info_sub_title text-primary"
                >
                  APEXX RACING
                </p>
                <div class="relative mb-3 glide__slide_info_title">
                  <h3
                    class="relative z-0 text-2xl font-bold text-white md:text-4xl"
                  >
                    {{ item.Title }}
                  </h3>
                  <span
                    class="absolute top-0 bottom-0 left-0 right-0 block bg-primary"
                  ></span>
                </div>
                <div class="glide__slide_info_button">
                  <a
                    :href="item.Link"
                    class="inline-block px-5 py-1 text-sm font-bold text-white transition-all duration-300 border border-black md:py-2 md:px-8 hover:bg-opacity-100 bg-opacity-70 bg-primary"
                  >
                    查看詳細資料
                  </a>
                </div>
              </div>
            </div>

            <div
              class="absolute top-0 bottom-0 left-0 right-0 z-10 transition-all duration-1000 bg-black opacity-0 dark_cover"
            ></div>
            <div
              class="relative z-0 block w-full overflow-hidden aspect-square md:aspect-[5/2] backdrop-blur-sm"
            >
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="$GetColumn('company_name')"
                class="hidden object-cover w-full h-full md:block"
              />
              <img
                :src="$ImageUrl(item.Image2)"
                :alt="$GetColumn('company_name')"
                class="block object-cover w-full h-full md:hidden"
              />
            </div>
          </li>
        </ul>

        <div
          class="absolute z-10 flex flex-col items-center py-0 transform -translate-y-1/2 right-5 top-1/2 glide__bullets"
          data-glide-el="controls[nav]"
        >
          <div
            v-for="(item, item_index) in carousel_data"
            :key="`carousel_bullet_${item_index}`"
            class="relative z-10 flex items-center justify-center w-2 h-2 glide__bullet"
            :class="
              item_index == carousel_data.length - 1 ? '' : 'mb-8 md:mb-10'
            "
            :data-glide-dir="`=${item_index}`"
          >
            <button
              class="relative z-10 w-2 h-2 bg-white rounded-full"
            ></button>
            <div
              class="absolute w-5 h-5 transform -translate-x-1/2 -translate-y-1/2 md:w-6 md:h-6 top-1/2 left-1/2"
            >
              <span
                class="z-0 block w-5 h-5 border border-white border-dashed rounded-full md:w-6 md:h-6"
              ></span>
            </div>
          </div>

          <div
            class="absolute top-0 bottom-0 transform -translate-x-1/2 bg-white bg-opacity-50 w-[1px] left-1/2"
          ></div>
        </div>

        <div
          v-if="glide_slider != null"
          class="absolute z-10 text-sm text-white bottom-5 right-5"
        >
          <p>{{ glide_slider.index + 1 }} / {{ carousel_data.length }}</p>
        </div>

        <div
          class="glide__progress absolute top-0 left-0 z-10 w-[2px] h-full bg-primary active"
        ></div>
      </div>
    </div>
  </section>
</template>

<style>
.glide__slide_info_line {
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.5s;
}

.glide__slide--active .dark_cover {
  opacity: 0.5;
}

.glide__slide--active .glide__slide_info_line {
  transform: scaleX(1);
  transform-origin: left;
}
.glide__slide_info_sub_title {
  opacity: 0;
  transition: all 0.5s 0.4s;
}
.glide__slide--active .glide__slide_info_sub_title {
  opacity: 1;
}
.glide__slide_info_title span {
  transform: scaleX(0);
  transform-origin: left;
}
.glide__slide--active .glide__slide_info_title span {
  animation: glide_title_cover 0.6s 0.5s ease-in-out forwards;
}
.glide__slide_info_title h3 {
  opacity: 0;
  transition: all 0s 0.8s;
}
.glide__slide--active .glide__slide_info_title h3 {
  opacity: 1;
}
.glide__slide_info_button {
  transform: translateY(-20%);
  opacity: 0;
  transition: all 0.5s 1s;
}
.glide__slide--active .glide__slide_info_button {
  transform: translateY(0%);
  opacity: 1;
}

.glide__progress.active {
  animation: glide_progress 5s linear forwards;
}
.glide__bullet div {
  opacity: 0;
  transform: scale(0) translate(-50%, -50%);
  transform-origin: left top;
  transition: all 0.3s;
}
.glide__bullet.glide__bullet--active div {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
  transition: all 0.3s;
}

.glide__bullet.glide__bullet--active div span {
  animation: bullet_border_rotate 3s infinite linear;
}

@keyframes bullet_border_rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes glide_progress {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}
@keyframes glide_title_cover {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  51% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}
</style>

<script>
import "@glidejs/glide/dist/css/glide.core.min.css";
import Glide from "@glidejs/glide";
export default {
  name: "HomeCarousel",
  data() {
    return {
      glide_slider: null,
      glide_progress_timer: null,
    };
  },
  computed: {
    carousel_data() {
      return this.$store.getters.home_cover_carousel;
    },
  },
  mounted() {
    this.glide_slider = new Glide(this.$refs.GlideSlider, {
      autoplay: 5000,
      gap: 0,
    });
    this.glide_slider.on("run.before", () => {
      if (this.$refs.GlideSlider) {
        this.$refs.GlideSlider.querySelector(
          ".glide__progress"
        ).classList.remove("active");
        if (this.glide_progress_timer != null) {
          clearTimeout(this.glide_progress_timer);
        }
        this.glide_progress_timer = setTimeout(() => {
          this.$refs.GlideSlider.querySelector(
            ".glide__progress"
          ).classList.add("active");
        }, 200);
      }
    });
    this.glide_slider.mount();
  },
};
</script>
