<template>
  <footer class="relative z-10 w-full overflow-x-hidden bg-black">
    <div class="w-full">
      <div
        class="flex flex-wrap items-center justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-14"
      >
        <div
          class="flex flex-wrap items-stretch order-2 w-full lg:order-1 md:w-auto"
        >
          <div class="w-1/2 mb-10 md:w-auto md:mr-20 md:mb-0">
            <p class="mb-3 text-sm text-zinc-400">網站地圖</p>
            <ol>
              <li
                v-for="(item, item_index) in menu_list"
                :key="`menu_${item_index}`"
                class="mb-1"
              >
                <router-link
                  :to="item.link"
                  class="text-sm font-light text-white transition-all duration-300 hover:text-zinc-400 hover:underline"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ol>
          </div>

          <div class="w-1/2 mb-10 md:w-auto md:mr-20 md:mb-0">
            <p class="mb-3 text-sm text-zinc-400">條款與規範</p>
            <ol>
              <!-- <li class="mb-1">
                <router-link
                  to="/terms/terms_of_customer"
                  class="text-sm font-light text-white transition-all duration-300 hover:underline hover:text-zinc-400"
                >
                  服務條款
                </router-link>
              </li> -->
              <li class="mb-1">
                <router-link
                  to="/terms/site_privacy"
                  class="text-sm font-light text-white transition-all duration-300 hover:underline hover:text-zinc-400"
                >
                  隱私權政策
                </router-link>
              </li>
              <li>
                <router-link
                  to="/terms/return_policy"
                  class="text-sm font-light text-white transition-all duration-300 hover:underline hover:text-zinc-400"
                >
                  退換貨政策
                </router-link>
              </li>
            </ol>
          </div>

          <div class="w-full md:w-auto md:mr-20">
            <p class="mb-3 text-sm text-zinc-400">聯絡資訊</p>
            <ol>
              <li class="flex items-center mb-2">
                <p class="mr-3 text-sm font-light text-white">聯絡電話</p>
                <p class="text-sm font-normal text-zinc-400 work_sans">
                  {{ $GetColumn("company_phone") }}
                </p>
              </li>
              <li class="flex items-center mb-2">
                <p class="mr-3 text-sm font-light text-white">公司地址</p>
                <p class="text-sm font-normal text-zinc-400 work_sans">
                  {{ $GetColumn("company_address") }}
                </p>
              </li>
              <li class="flex items-center mb-2">
                <p class="mr-3 text-sm font-light text-white">電子信箱</p>
                <p class="text-sm font-normal text-zinc-400 work_sans">
                  {{ $GetColumn("company_email") }}
                </p>
              </li>
              <li class="flex items-center">
                <p class="mr-3 text-sm font-light text-white">營業時間</p>
                <p class="text-sm font-normal text-zinc-400 work_sans">
                  {{ $GetColumn("company_time") }}
                </p>
              </li>
            </ol>
          </div>
        </div>

        <div
          class="flex flex-col items-center order-1 w-full mb-14 lg:mb-0 lg:items-end lg:flex-1 lg:order-2 lg:w-auto"
        >
          <router-link to="/" class="mb-5">
            <img :src="$ImageUrl($GetColumn('footer_logo'))" class="w-44" />
          </router-link>
          <ol class="flex items-center justify-center w-44">
            <li class="px-3" v-if="$GetColumn('company_facebook') != ''">
              <a
                :href="$GetColumn('company_facebook')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-zinc-500"
              >
                <i class="text-xl text-current icon-facebook"></i>
              </a>
            </li>
            <li class="px-3" v-if="$GetColumn('company_instagram') != ''">
              <a
                :href="$GetColumn('company_instagram')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-zinc-500"
              >
                <i class="text-xl text-current icon-instagram"></i>
              </a>
            </li>
            <li class="px-3" v-if="$GetColumn('company_line') != ''">
              <a
                :href="$GetColumn('company_line')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-zinc-500"
              >
                <i class="text-xl text-current icon-line"></i>
              </a>
            </li>
            <li class="px-3" v-if="$GetColumn('company_youtube') != ''">
              <a
                :href="$GetColumn('company_youtube')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-zinc-500"
              >
                <i class="text-xl text-current icon-youtube"></i>
              </a>
            </li>
            <li class="px-3" v-if="$GetColumn('company_tiktok') != ''">
              <a
                :href="$GetColumn('company_tiktok')"
                target="_blank"
                class="text-white transition-all duration-300 hover:text-zinc-500"
              >
                <i class="text-xl text-current icon-tiktok"></i>
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div
        class="max-w-[1440px] mx-auto w-full flex-wrap py-4 flex items-center justify-center md:justify-between md:px-10 px-5"
      >
        <div class="mb-5 md:mb-0">
          <img
            v-if="$GetColumn('payment_image') != ''"
            class="h-7"
            :src="$ImageUrl($GetColumn('payment_image'))"
          />
        </div>
        <div class="flex justify-center w-full md:w-auto">
          <button
            @click="ScrollToTop"
            class="inline-flex items-center py-2 pl-8 pr-6 text-sm font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-base bg-primary md:hover:bg-opacity-100"
          >
            回到頂部
            <i class="block ml-3 transform -rotate-90 icon-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="w-full border-t border-white border-opacity-10">
      <div
        class="flex items-center flex-wrap justify-center md:justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-4"
      >
        <p class="mb-2 text-xs work_sans text-gray md:mb-0">
          ©{{ year }} {{ $GetColumn("company_name") }}
        </p>

        <p class="w-full text-xs text-center text-gray md:w-auto md:text-start">
          DESIGN BY
          <a
            class="ml-2 text-primary"
            href="https://www.yongxin-design.com"
            target="_blank"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "關於我們",
          sub_title: "About",
          link: "/about",
        },
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/scooters/all",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Video",
          link: "/video/list",
        },
        {
          title: "客服中心",
          sub_title: "Contact",
          link: "/support",
        },
        {
          title: "經銷據點",
          sub_title: "Dealers",
          link: "/dealers",
        },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
