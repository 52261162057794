<template>
  <div ref="MainContent" class="absolute right-0 top-full drop_down_box">
    <ol class="bg-black bg-opacity-90">
      <li
        v-for="(item, item_index) in menu_category_data"
        :key="`drop_down_menu_${item_index}`"
      >
        <router-link
          :to="`/scooters/${item.MenuCategoryID}`"
          @click.native="Close()"
          class="block px-5 py-3 text-white transition-all duration-300 md:hover:text-primary drop_down_item"
        >
          {{ item.Title }}
        </router-link>
      </li>
    </ol>
  </div>
</template>

<style>
.drop_down_box {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s;
}
.drop_down_box.active {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(0px);
}
</style>

<script>
export default {
  name: "DropDown",
  methods: {
    Close() {
      this.$refs.MainContent.classList.remove("active");
    },
  },
  computed: {
    menu_category_data() {
      return this.$store.state.menu_category_data;
    },
  },
};
</script>
