<template>
  <div
    id="app"
    class="relative z-10 flex flex-col w-full min-h-screen bg-zinc-900"
  >
    <MainLoading />

    <ShopCartDrawer />

    <template v-if="all_data_loaded">
      <MainHeader
        :menu_status="menu_status"
        @menu-action="menu_status = !menu_status"
      />
      <MainMenu
        ref="MainMenu"
        :menu_status="menu_status"
        @menu-action="menu_status = !menu_status"
      />
      <MainDialog />
      <!-- Cookie與廣告資料搜集同意視窗 -->
      <CookieConsentDialog />

      <!-- 114 77 -->
      <div
        class="relative z-10 flex-1"
        :style="
          ['dealers', 'home', 'about', 'collections'].indexOf($route.name) == -1
            ? `padding-top: ${view_pt}px`
            : ''
        "
      >
        <router-view
          class="relative z-10"
          :view_pt="view_pt"
          :window_size="window_size"
          @set-meta="SetMetaData"
          @load-image="LoadImage"
        />
      </div>
      <ContactFooter />
      <MainFooter />
    </template>

    <div
      class="fixed top-0 bottom-0 left-0 right-0 z-0 bg-repeat opacity-[.025]"
      style="background-image: url('/img/dot_bg.png'); background-size: 20px"
    ></div>
  </div>
</template>

<script>
import { ImageLoader } from "@/gsap/ImageLoader";
import { GetMetaData } from "@/common/meta";
import { setLocalStorage, delLocalStorage } from "@/common/cookie";
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainMenu from "@/components/MainMenu.vue";
import ContactFooter from "@/components/ContactFooter.vue";
import ShopCartDrawer from "@/components/ShopCartDrawer.vue";
import CookieConsentDialog from "@/components/CookieConsentDialog.vue";
export default {
  name: "App",
  components: {
    MainDialog,
    MainLoading,
    MainHeader,
    MainFooter,
    MainMenu,
    ContactFooter,
    ShopCartDrawer,
    CookieConsentDialog,
  },
  data() {
    return {
      image_loader: null,
      menu_status: false,
      meta_data: null,
      window_size: window.innerWidth,
      view_pt: 0,
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    SetMetaData(title, content, image) {
      this.meta_data = GetMetaData(title, content, image);
    },
    CheckAnnouncementHeight() {
      if (document.querySelector("#MainHeader")) {
        this.view_pt = document.querySelector("#MainHeader").clientHeight;
      } else {
        this.view_pt = 0;
      }
    },
  },
  watch: {
    all_data_loaded() {
      if (this.all_data_loaded) {
        this.$store.dispatch("shopcart_module/GetShopCart");
        this.$store.commit("SetLoading", -1);
        this.$nextTick(() => {
          this.CheckAnnouncementHeight();
        });
      }
    },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  mounted() {
    this.$store.commit("SetLoading", 1);
    this.image_loader = new ImageLoader();
    window.addEventListener("resize", () => {
      this.menu_status = false;
      this.window_size = window.innerWidth;
      this.CheckAnnouncementHeight();
    });

    const currentUrl = new URL(window.location.href);
    const urlParams = currentUrl.searchParams;

    if (urlParams.has("order_memo")) {
      setLocalStorage("order_memo", urlParams.get("order_memo"));
    } else {
      delLocalStorage("order_memo");
    }
  },
  created() {
    !this.all_data_loaded ? this.$store.dispatch("getAllData") : "";
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

<style src="@/assets/css/output.css"></style>
