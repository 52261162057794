<template>
  <section
    id="HomeSocialSection"
    ref="MainContent"
    class="mb-20 overflow-hidden main_container"
  >
    <div class="flex flex-wrap items-stretch justify-between -mx-5">
      <div data-section-card class="w-full px-5 mb-5 md:w-1/2 md:mb-0">
        <a
          :href="$GetColumn('home_social_1_link')"
          target="_blank"
          class="relative block w-full aspect-video social_card"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center transition-all duration-300 text_box"
          >
            <div class="text-center">
              <h4
                class="mb-3 text-3xl font-black text-white sm:mb-0 sm:text-4xl"
              >
                {{ $GetColumn("home_social_1_title") }}
              </h4>
              <p
                class="inline-flex items-center px-4 py-1 text-xs font-medium text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-sm sm:font-bold bg-primary md:hover:bg-opacity-100"
              >
                {{ $GetColumn("home_social_1_sub_title") }}
              </p>
            </div>
          </div>
          <div
            class="absolute top-0 bottom-0 left-0 right-0 z-10 transition-all duration-500 bg-black cover_box opacity-60"
          ></div>
          <img
            :src="$ImageUrl($GetColumn('home_social_1_image'))"
            class="relative z-0 object-cover w-full h-full"
          />
        </a>
      </div>
      <div data-section-card class="w-full px-5 md:w-1/2">
        <a
          :href="$GetColumn('home_social_2_link')"
          target="_blank"
          class="relative block w-full aspect-video social_card"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center transition-all duration-300 text_box"
          >
            <div class="text-center">
              <h4
                class="mb-3 text-3xl font-black text-white sm:mb-0 sm:text-4xl"
              >
                {{ $GetColumn("home_social_2_title") }}
              </h4>
              <p
                class="inline-flex items-center px-4 py-1 text-xs font-medium text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-sm sm:font-bold bg-primary md:hover:bg-opacity-100"
              >
                {{ $GetColumn("home_social_2_sub_title") }}
              </p>
            </div>
          </div>
          <div
            class="absolute top-0 bottom-0 left-0 right-0 z-10 transition-all duration-500 bg-black cover_box opacity-60"
          ></div>
          <img
            :src="$ImageUrl($GetColumn('home_social_2_image'))"
            class="relative z-0 object-cover w-full h-full"
          />
        </a>
      </div>
    </div>
  </section>
</template>

<style>
#HomeSocialSection .social_card:hover .cover_box {
  opacity: 0.8;
}
#HomeSocialSection .social_card:hover .text_box {
  transform: scale(1.1);
}
</style>

<script>
import { AboutSection } from "@/gsap/home/AboutSection";
export default {
  name: "HomeSocialFooter",
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new AboutSection(this.$refs.MainContent);
    this.section_gsap.reset();
    this.section_gsap.setup();
  },
};
</script>
